import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getProductReviews } from 'redux/modules/product-details/selectors/product-properties';

import { getPDPUrl } from 'utils/product';
import servingInstructions from 'constants/servingInstructions';

import ProductAttributes from 'components/Product/Attributes';

import StarRating from 'components/StarRating';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';

import ProductPodPromotions from 'components/ProductPod/Promotions';

import styles from './index.scss';

const ProductPodAttributes = ({
  attributes,
  cookingStatus,
  entertaining,
  productName,
  productId,
  productType,
  qualifier,
  searchType,
  reportAnalyticsClick,
}) => {
  const hasReviews = useSelector(
    state => (getProductReviews(state, productId)?.reviewCount || 0) > 0,
  );
  const servingInstructionsAttributes = servingInstructions[cookingStatus];

  const showEntertainingAttributes = entertaining && !!attributes && Object.keys(attributes).length;
  const showServingInstructions = entertaining && servingInstructionsAttributes;

  return productId ? (
    <>
      {hasReviews ? (
        <StarRating
          lighter
          isSizeSmall
          hasSchema={false}
          linkTo={getPDPUrl(productId, productName)}
          productId={productId}
          clickHandler={reportAnalyticsClick}
        />
      ) : (
        <div className={styles.ratingWrapper}>
          <Typography styleAs="paragraph"> No reviews yet</Typography>
        </div>
      )}
      <ProductPodPromotions
        productType={productType}
        productId={productId}
        searchType={searchType}
        qualifier={qualifier}
        reportAnalyticsClick={reportAnalyticsClick}
      />
      {showEntertainingAttributes && (
        <div className={styles.podEntertainingAttributes}>
          <div className={styles.entertainingDetails}>
            <ProductAttributes
              noIcons
              shortLeadTime
              attributes={attributes}
              identifier={`entertainingAttributes${qualifier ? `-${qualifier}` : ''}`}
            />
          </div>
        </div>
      )}
      {showServingInstructions && (
        <div className={styles.servingInstructions}>
          <ProductAttributes noIcons attributes={{ [servingInstructionsAttributes]: true }} />
        </div>
      )}
    </>
  ) : null;
};

ProductPodAttributes.displayName = 'ProductPodAttributes';

ProductPodAttributes.propTypes = {
  attributes: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  cookingStatus: PropTypes.oneOf(Object.keys(servingInstructions)),
  entertaining: PropTypes.bool,
  productName: PropTypes.string,
  productId: PropTypes.string,
  qualifier: PropTypes.string,
  searchType: PropTypes.string,
  productType: PropTypes.string,
  reportAnalyticsClick: PropTypes.func,
};

ProductPodAttributes.defaultProps = {
  attributes: null,
  cookingStatus: undefined,
  entertaining: false,
  productName: undefined,
  productId: undefined,
  qualifier: undefined,
  searchType: '',
  productType: null,
  reportAnalyticsClick: () => null,
};

export default memo(ProductPodAttributes);
