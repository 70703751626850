import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { dataAttributesGenerator } from 'utils/data-attribute-generator';

import ProductImage from 'components/Product/Image';
import ProductBadge from 'components/Product/Badge';

import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import { getPDPUrl } from 'utils/product';

import styles from './index.scss';

const ProductPodImage = ({
  fullSize,
  name,
  onClick,
  productId,
  thumbnail,
  disabled,
  isEntertaining,
  position,
}) => {
  if (!productId) return null;

  return (
    <div
      data-testid="product-pod-image"
      {...{
        className: classNames(styles.image, {
          [styles.fullSize]: fullSize,
          [styles.disabled]: disabled,
        }),
        ...dataAttributesGenerator({
          actiontype: 'redirect',
          origincomponent: 'ProductPod',
          shortdescription: `view product details for ${name}`,
        }),
      }}
    >
      <TextLink underline="hover" href={getPDPUrl(productId, name)} onClick={onClick}>
        <span className="sr-only">view product details for</span>
        <ProductImage
          {...{
            name,
            placement: fullSize ? 'carousel' : 'productPod',
            position,
            url: thumbnail,
          }}
        />
      </TextLink>
      {isEntertaining && <ProductBadge text="Entertaining" variant="entertaining" />}
    </div>
  );
};

ProductPodImage.displayName = 'ProductPodImage';

ProductPodImage.propTypes = {
  fullSize: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  productId: PropTypes.string,
  thumbnail: PropTypes.string,
  disabled: PropTypes.bool,
  isEntertaining: PropTypes.bool,
  position: PropTypes.number,
};

ProductPodImage.defaultProps = {
  fullSize: false,
  name: null,
  onClick: () => {},
  productId: null,
  thumbnail: null,
  disabled: false,
  isEntertaining: false,
  position: undefined,
};

export default memo(ProductPodImage);
