import { connect } from 'react-redux';
import { getPayloadById } from 'redux/modules/experiments/selectors/index';
import {
  getProductConflictById,
  isItemEditable,
  trolleyControlsDisabled,
} from 'redux/modules/conflicts/selectors';
import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';

import { getProductById } from 'redux/modules/entities/selectors/products';
import { getEntertainingAttributes } from 'redux/modules/product-details/selectors/product-attributes';
import { getProductCookingStatus } from 'redux/modules/product-details/selectors/product-contents';
import { getOrderIdFromPath, getLocation } from 'redux/modules/routing/selectors';
import { getQuantityByLineNumber } from 'redux/modules/trolley/selectors/get-item-quantity';
import { getIsFavourite } from 'redux/modules/favourites-products/selectors';
import { addRemoveFavourite } from 'redux/modules/search-and-browse/actions/add-remove-favourite';
import { toggleListMembership } from 'redux/modules/shopping-list/actions/toggle-list-membership';
import { getMealDealBuilderLineNumbers } from 'redux/modules/meal-deals/selectors';
import {
  getMealDealPromotion,
  getMultibuyPromotionPath,
} from 'redux/modules/entities/selectors/promotions';

import { isMealDealPage } from 'components/MealDealPage/helpers';
import { getOptimisticItemByLineNumber } from 'redux/modules/trolley/selectors/get-optimistic-item';
import { getQuantityOrFixUom } from 'hooks/utils';
import ProductPod from './ProductPod';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export default connect(
  (state, { id, searchType }) => {
    const productData = getProductById(state, id) || EMPTY_OBJECT;
    const {
      depositCharge,
      displayPriceEstimated,
      displayPriceQualifier,
      id: productId,
      lineNumber: lineNum,
      marketingBadges = EMPTY_ARRAY,
      maxPersonalisedMessageLength,
      metadata,
      name: productName,
      productType,
      promotions = EMPTY_ARRAY,
      sponsorshipId,
      thumbnail,
    } = productData;
    const lineNumber = lineNum || extractLineNumberFromSku(productId);
    const trolleyQuantity = getQuantityByLineNumber(state, lineNumber);
    const { amount: trolleyQuantityAmount, uom: trolleyQuantityUom } = getQuantityOrFixUom(
      state,
      productId,
      trolleyQuantity,
    );
    const optimisticTrolleyItem = getOptimisticItemByLineNumber(state, lineNumber);

    const mealDealPromotion = getMealDealPromotion(state, promotions);
    const mealDealBuilderLineNumbers =
      isMealDealPage(searchType) && mealDealPromotion
        ? getMealDealBuilderLineNumbers(state, mealDealPromotion.promotionId)
        : EMPTY_ARRAY;
    const isInMealDeal = mealDealBuilderLineNumbers.some(product => product === lineNumber);

    const optimisticValues = {
      quantity: optimisticTrolleyItem
        ? optimisticTrolleyItem.quantity
        : {
            amount: trolleyQuantityAmount,
            uom: trolleyQuantityUom,
          },
    };

    const multibuyPromotionPath = getMultibuyPromotionPath(state, promotions);

    return {
      attributes: getEntertainingAttributes(state, productId),
      conflict: getProductConflictById(state, productId),
      cookingStatus: getProductCookingStatus(state, productId),
      depositCharge,
      displayPriceEstimated,
      displayPriceQualifier,
      hasPromotions: !!promotions?.length,
      isDisabled: trolleyControlsDisabled(state, lineNumber),
      isEditable: isItemEditable(state, 'products', productId),
      isFavourite: getIsFavourite(state, lineNumber),
      isInMealDeal,
      lineNumber,
      location: getLocation(state),
      marketingBadges,
      maxPersonalisedMessageLength,
      metadata,
      multibuyPromotionPath,
      optimisticTrolleyQuantityAmount: optimisticValues.quantity.amount,
      optimisticTrolleyQuantityUom: optimisticValues.quantity.uom,
      orderId: getOrderIdFromPath(state),
      productId,
      productName,
      productType,
      slidedownAdvertDetails: getPayloadById(state, productId),
      sponsorshipId,
      thumbnail,
    };
  },
  {
    addRemoveFavourite,
    toggleListMembership,
  },
)(ProductPod);
